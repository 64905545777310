/* eslint-env browser */
import React from "react";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Archive from "@mui/icons-material/Archive";
import Unarchive from "@mui/icons-material/Unarchive";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";

function ArchiveStudyDialog({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Error handling
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();
  const dispatch = useStudiesDispatch();

  //---------------------------------------------------------------------------
  // Helper functions
  //---------------------------------------------------------------------------
  const clickedArchiveStudy = React.useCallback(async () => {
    // get inbox item count for archiving study dialog
    let countMessage = "";
    try {
      const {
        headers: {count: inboxCount},
      } = await axios({
        method: "get",
        url: "/inboxItems",
        params: {
          "$enrollment.study.id$": study.id,
          completed: false,
        },
      });

      if (inboxCount === "0") {
        countMessage = "";
      } else if (inboxCount === "1") {
        countMessage = `There is an unanalyzed item.`;
      } else {
        countMessage = `There are ${inboxCount} unanalyzed items.`;
      }
    } catch (err) {
      // do nothing because the message just won't display if the request failed
    }

    // display the dialog for archiving the study
    let clickedCancel = false;
    try {
      await confirm({
        title: "Archive this study?",
        content: (
          <>
            {countMessage && <Typography pb={2}>{countMessage}</Typography>}
            <Alert
              message="All items for this study will be hidden on the Analyze and Completed pages."
              level="warning"
            />
          </>
        ),
        confirmationText: "Archive Study",
      });
    } catch (err) {
      clickedCancel = true;
    }

    if (!clickedCancel) {
      // update the study in the database
      try {
        await axios({
          method: "post",
          url: `/studies/${study.id}/archive`,
        });

        const {
          data: [updatedStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: study.id},
        });
        dispatch({type: "updated", updatedElement: updatedStudy});
      } catch (err) {
        setError(err);
      }
    }
  }, [confirm, study, dispatch]);

  const clickedUnarchiveStudy = React.useCallback(async () => {
    let clickedCancel = false;
    try {
      await confirm({
        title: "Unarchive this study?",
        content: (
          <Alert
            message="All items for this study will be displayed on the Analyze and Completed pages."
            level="warning"
          />
        ),
        confirmationText: "Unarchive Study",
      });
    } catch (err) {
      clickedCancel = true;
    }

    if (!clickedCancel) {
      try {
        await axios({
          method: "post",
          url: `/studies/${study.id}/unarchive`,
        });

        const {
          data: [updatedStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: study.id},
        });
        dispatch({type: "updated", updatedElement: updatedStudy});
      } catch (err) {
        setError(err);
      }
    }
  }, [confirm, study, dispatch]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <MenuItem
        onClick={study.studyState === "archived" ? clickedUnarchiveStudy : clickedArchiveStudy}
        data-cy={`${study.studyState === "archived" ? "unarchive" : "archive"}-study-button-${study.id}`}
      >
        <ListItemIcon>
          {study.studyState === "archived" ? <Unarchive fontSize="small" /> : <Archive fontSize="small" />}
        </ListItemIcon>
        <ListItemText>{study.studyState === "archived" ? "Unarchive Study" : "Archive Study"}</ListItemText>
      </MenuItem>
    </>
  );
}

ArchiveStudyDialog.propTypes = {
  study: PropTypes.object.isRequired,
};

export default ArchiveStudyDialog;
