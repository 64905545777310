/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import keyBy from "lodash/keyBy";
import {DateTime} from "luxon";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Assignment from "@mui/icons-material/Assignment";
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useEnvironmentVariables from "../../../../components/hooks/useEnvironmentVariables.jsx";
import useStudyTypeNames from "../../../../components/hooks/useStudyTypeNames.jsx";
import {useStudiesDispatch} from "../../../../contexts/StudiesContext.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../../shared/react/TableLoading.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";
import SharedSettingsFields from "../StudyActionComponents/SharedSettingsFields.jsx";
import StudyDurationField from "../StudyActionComponents/StudyDurationField.jsx";
import StudySettingsConfirmation from "../StudyActionComponents/StudySettingsConfirmation.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

function ConvertToCemForm({
  // Props
  study,
  enrollmentToUse,
  enrollmentStartTimeToUse,
  handleClose,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const displayableStudyTypes = useStudyTypeNames();
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Loading state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [pendingUpdateSettingsUser, setPendingUpdateSettingsUser] = React.useState("");

  const [oldSettings, setOldSettings] = React.useState({
    studyType: study.studyType,
    pinCode: 0,
    studyDays: Math.ceil(study.configuredDuration / 24),
  });
  const [arrhythmiaSettings, setArrhythmiaSettings] = React.useState({});
  const [deviceConfigurations, setDeviceConfigurations] = React.useState({});
  const [CEMIsAllowed, setCEMIsAllowed] = React.useState(true);

  const getConvertToCemData = React.useCallback(async () => {
    try {
      const [
        {data: settingsResponse},
        {data: deviceConfigurationsResponse},
        {data: arrhythmiaSettingsResponse},
        {data: studyConfigurationsResponse},
      ] = await Promise.all([
        axios({
          url: "/actions",
          method: "get",
          params: {
            deviceId: enrollmentToUse.deviceId,
            enrollmentId: enrollmentToUse.enrollmentId,
            name: "updateSettings",
            status: {$ne: "failed"},
            order: [["actionCount", "DESC"]],
          },
        }),
        axios({
          url: "/facilityDeviceConfigurations/h3r",
          method: "get",
          params: {
            facilityId: study.facilityId,
            order: [["name", "ASC"]],
          },
        }),
        axios({
          url: "/facilityArrhythmiaSettings",
          method: "get",
          params: {
            facilityId: study.facilityId,
            order: [["name", "ASC"]],
          },
        }),
        axios({
          url: "/facilityStudyConfigurations",
          method: "get",
          params: {
            facilityId: study.facilityId,
          },
        }),
      ]);

      // get arrhythmia settings for the form
      // Convert pause duration to seconds and hpFilter to Hz
      const formattedArrhythmiaSettings = keyBy(
        arrhythmiaSettingsResponse.map((setting) => {
          const pauseDuration = setting.pauseDuration / 1000;
          const hpFilter = setting.hpFilter / 100;

          return {...setting, pauseDuration, hpFilter};
        }),
        "id"
      );
      // get device configurations for the form
      const formattedDeviceConfigurations = keyBy(deviceConfigurationsResponse, "id");

      try {
        const foundSettings = JSON.parse(settingsResponse[0].originalData);

        if (study.pendingDuration) {
          foundSettings.studyDays = Math.ceil(study.pendingDuration / 24);
        } else if (!foundSettings.studyDays && foundSettings.studyHours) {
          foundSettings.studyDays = Math.ceil(foundSettings.studyHours / 24);
        }

        // Convert from milliseconds to seconds
        foundSettings.pauseDuration /= 1000;
        // Convert from hz/100 to hz
        foundSettings.hpFilter /= 100;

        setOldSettings((prev) => ({...prev, ...foundSettings}));
      } catch (err) {
        /* do nothing */
      }

      const foundUser = settingsResponse.find((setting) => setting.status === "pending")?.createdBy || "";

      if (studyConfigurationsResponse[0].allowedStudyTypes !== null) {
        setCEMIsAllowed(studyConfigurationsResponse[0].allowedStudyTypes?.cem);
      } else {
        // If allowed study types row does not exist, allow the conversion
        setCEMIsAllowed(true);
      }
      setArrhythmiaSettings(formattedArrhythmiaSettings);
      setDeviceConfigurations(formattedDeviceConfigurations);
      setPendingUpdateSettingsUser(foundUser);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [study, enrollmentToUse]);

  useInterval(getConvertToCemData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const enrollmentStartTime = React.useMemo(() => {
    return DateTime.fromISO(enrollmentStartTimeToUse);
  }, [enrollmentStartTimeToUse]);

  const elapsedStudyDays = React.useMemo(() => {
    if (enrollmentStartTime > DateTime.now()) {
      return 0;
    }
    return Math.floor(DateTime.now().diff(enrollmentStartTime, ["days"]).days);
  }, [enrollmentStartTime]);

  const minTotalStudyDays = React.useMemo(() => {
    return elapsedStudyDays + 1;
  }, [elapsedStudyDays]);

  const associatedStudyDays = React.useMemo(() => {
    if (study.initialStudyAssociation) {
      return Math.ceil(study.initialStudyAssociation.initialStudy.pendingDuration / 24);
    }
    if (study.followUpStudyAssociation) {
      return Math.ceil(study.followUpStudyAssociation.followUp.pendingDuration / 24);
    }
    return null;
  }, [study.initialStudyAssociation, study.followUpStudyAssociation]);

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const {handleSubmit, control, setValue, watch} = useForm();
  const {isDirty} = useFormState({control});
  const dispatch = useStudiesDispatch();

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      //---------------------------------------------------------------------------
      // Construct settings data for the device
      //---------------------------------------------------------------------------
      const studyHours = Number(data.studyDays) * 24;

      const deviceConfiguration = deviceConfigurations[data.deviceConfig];
      if (deviceConfiguration.deviceType) {
        deviceConfiguration.deviceType = "h3r";
      }

      const updateSettingsData = {
        tachyBpm: Number(data.tachyBpm),
        bradyBpm: Number(data.bradyBpm),
        pauseDuration: Number(data.pauseDuration) * 1000, // convert seconds to milliseconds
        episodeDuration: Number(data.episodeDuration),
        hpFilter: Number(data.hpFilter) * 100, // convert hz to hz/100
        lpFilter: Number(data.lpFilter),
        notchFilter: Number(data.notchFilter),
        pinCode: oldSettings.pinCode,
        deviceConfig: deviceConfiguration,
        studyNote: data.comment || oldSettings.studyNote || "",
        studyHours,
      };

      //---------------------------------------------------------------------------
      // Confirm updates with user displayed values
      //---------------------------------------------------------------------------
      try {
        const newSettings = {
          ...updateSettingsData,
          pauseDuration: Number(data.pauseDuration),
          hpFilter: Number(data.hpFilter),
          studyDays: data.studyDays,
        };
        delete newSettings.studyHours;

        await confirm({
          title: "Update settings for this study?",
          content: <StudySettingsConfirmation oldSettings={oldSettings} newSettings={newSettings} />,
          confirmationText: "Update",
          dialogProps: {
            sx: {
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "650px", // set width so study type confirmation doesn't wrap
                },
              },
            },
          },
        });
      } catch (err) {
        // If the user cancelled the confirmation, do nothing
        setSubmitting(false);
        return;
      }

      //---------------------------------------------------------------------------
      // Create the update settings action!
      //---------------------------------------------------------------------------
      const updateSettingsAction = {
        deviceId: enrollmentToUse.deviceId,
        enrollmentId: enrollmentToUse.enrollmentId,
        facilityId: study.facilityId,
        comment: data.comment,
        data: updateSettingsData,
      };

      try {
        await axios({
          url: `/studies/${study.id}/convert-to-cem`,
          method: "post",
          data: updateSettingsAction,
        });

        const {
          data: [updatedStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: study.id},
        });
        dispatch({type: "updated", updatedElement: updatedStudy});

        handleClose();
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [
      deviceConfigurations,
      oldSettings,
      study.facilityId,
      study.id,
      enrollmentToUse.deviceId,
      enrollmentToUse.enrollmentId,
      confirm,
      dispatch,
      handleClose,
    ]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {loading && <TableLoading />}
      {!loading && (
        <TabPanel value="convertToCem" data-cy="convert-to-cem">
          {features.downgradeAuthorized && study.downgradeAuthorized === false && (
            <Alert
              message="Downgrading to a CEM study is not authorized."
              level="warning"
              otherProps={{mb: 2, "data-cy": "downgrade-not-authorized-message"}}
            />
          )}
          {(!features.downgradeAuthorized || [true, null].includes(study.downgradeAuthorized)) && (
            <>
              <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
              {pendingUpdateSettingsUser !== "" && CEMIsAllowed && (
                <Alert
                  message={`The settings changes requested by ${pendingUpdateSettingsUser} have not been sent to the device. The settings displayed below include the pending changes.`}
                  level="warning"
                  otherProps={{mb: 2}}
                />
              )}

              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} container rowGap={1}>
                  <Grid item xs={12}>
                    <IconWithText
                      icon={<Smartphone color="tertiary" />}
                      text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IconWithText
                      icon={<Assignment color="tertiary" />}
                      text={
                        <Typography variant="body2">
                          {displayableStudyTypes[study.studyType]}
                          <br />
                          &emsp;&#8618;{displayableStudyTypes.cem}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                {CEMIsAllowed && (
                  <FormProvider {...{control, watch, setValue}}>
                    {study.studyType !== "cardiacRehab" && (
                      <Grid item xs={6} sx={{display: "inline-flex"}}>
                        <StudyDurationField
                          studyDays={oldSettings.studyDays}
                          minTotalStudyDays={minTotalStudyDays}
                          elapsedStudyDays={elapsedStudyDays}
                          enrollmentStartTime={enrollmentStartTime}
                          timeZone={study.timeZone}
                          associatedStudyDays={associatedStudyDays}
                        />
                      </Grid>
                    )}
                    {!["holter", "extendedHolter"].includes(study.studyType) && (
                      <Grid item xs={12}>
                        <SharedSettingsFields
                          settings={oldSettings}
                          arrhythmiaSettings={arrhythmiaSettings}
                          deviceConfigurations={deviceConfigurations}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CommentField />
                    </Grid>
                  </FormProvider>
                )}
                {!CEMIsAllowed && (
                  <Alert
                    message="CEM studies have been disabled by your facility. If you believe this is a mistake, please contact one of your facility admins to update your facility's study configuration."
                    level="error"
                    otherProps={{margin: 2}}
                  />
                )}
                <Grid item xs={12} display="inline-flex" justifyContent="flex-end">
                  <Box mx={3}>
                    <CancelButton
                      color="secondary"
                      isDirty={isDirty}
                      onClick={handleClose}
                      data-cy="cancel-action-button"
                    >
                      Cancel
                    </CancelButton>
                  </Box>
                  <LoadingButton
                    data-cy="submit-action-button"
                    disabled={!CEMIsAllowed || submitting || !isDirty}
                    variant="contained"
                    color="secondary"
                    loading={submitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Downgrade to CEM Study
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}
        </TabPanel>
      )}
    </>
  );
}

ConvertToCemForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  enrollmentStartTimeToUse: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ConvertToCemForm;
