/* eslint-env browser */
import React from "react";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Delete from "@mui/icons-material/Delete";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import {useStudiesDispatch} from "../../../contexts/StudiesContext.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import CompactStudyInfo from "../../../shared/react/CompactStudyInfo.jsx";

function DeleteStudyDialog({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Error handling
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Helper functions
  //---------------------------------------------------------------------------
  const dispatch = useStudiesDispatch();

  const clickedDeleteStudy = React.useCallback(async () => {
    try {
      await confirm({
        title: "Confirm Action",
        content: (
          <>
            <Alert
              message={`
                Are you sure you want to delete this study?
                ${study.followUpStudyAssociation ? ` Follow-up study ID ${study.followUpStudyAssociation.id} will also be deleted. ` : " "}
                This action cannot be undone.`}
              level="warning"
            />

            <Box mt={2}>
              <CompactStudyInfo study={study} />
            </Box>
          </>
        ),
        confirmationText: "Ok",
      });
    } catch (err) {
      // If confirmation was cancelled, do nothing
      return;
    }

    try {
      await axios({
        method: "delete",
        url: `/studies/${study.id}`,
      });

      dispatch({type: "deleted", id: study.id});
    } catch (err) {
      setError(err);
    }
  }, [confirm, study, dispatch]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <MenuItem onClick={clickedDeleteStudy} data-cy={`delete-study-button-${study.id}`}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete Study</ListItemText>
      </MenuItem>
    </>
  );
}

DeleteStudyDialog.propTypes = {
  study: PropTypes.object.isRequired,
};

export default DeleteStudyDialog;
