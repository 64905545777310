/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import keyBy from "lodash/keyBy";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Assignment from "@mui/icons-material/Assignment";
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useEnvironmentVariables from "../../../../components/hooks/useEnvironmentVariables.jsx";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import useStudyTypeNames from "../../../../components/hooks/useStudyTypeNames.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../../shared/react/TableLoading.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";
import SharedSettingsFields from "../StudyActionComponents/SharedSettingsFields.jsx";
import StudyDurationField from "../StudyActionComponents/StudyDurationField.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

function ResumeStudyForm({
  // Props
  study,
  enrollmentToUse,
  enrollmentStartTimeToUse,
  handleClose,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {fullName} = useJwt();
  const displayableStudyTypes = useStudyTypeNames();
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Loading state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  // conditionals needed for warnings and required fields
  const [canResumeStudy, setCanResumeStudy] = React.useState(false);
  const [pendingUpdateSettingsUser, setPendingUpdateSettingsUser] = React.useState("");

  // data need for forms
  const [oldSettings, setOldSettings] = React.useState({
    studyType: study.studyType,
    pinCode: 0,
    studyDays: Math.ceil(study.configuredDuration / 24),
  });
  const [arrhythmiaSettings, setArrhythmiaSettings] = React.useState({});
  const [deviceConfigurations, setDeviceConfigurations] = React.useState({});

  const getResumeStudyData = React.useCallback(async () => {
    try {
      const [
        {data: resumeStudyActionsResponse},
        {data: settingsResponse},
        {data: deviceConfigurationsResponse},
        {data: arrhythmiaSettingsResponse},
      ] = await Promise.all([
        axios({
          url: "/actions",
          method: "get",
          params: {
            deviceId: enrollmentToUse.deviceId,
            subType: "resumeStudy",
            enrollmentId: {$or: ["null", enrollmentToUse.enrollmentId]}, // Include null enrollmentIds (shown for all enrollments)
            status: {$or: ["sent", "pending"]},
          },
        }),
        axios({
          url: "/actions",
          method: "get",
          params: {
            deviceId: enrollmentToUse.deviceId,
            enrollmentId: enrollmentToUse.enrollmentId,
            name: "updateSettings",
            status: {$ne: "failed"},
            order: [["actionCount", "DESC"]],
          },
        }),
        axios({
          url: "/facilityDeviceConfigurations/h3r",
          method: "get",
          params: {
            facilityId: study.facilityId,
            order: [["name", "ASC"]],
          },
        }),
        axios({
          url: "/facilityArrhythmiaSettings",
          method: "get",
          params: {
            facilityId: study.facilityId,
            order: [["name", "ASC"]],
          },
        }),
      ]);

      // Users can only send an resume study action if there are no pending or sent end study actions
      if (resumeStudyActionsResponse.length === 0) {
        setCanResumeStudy(true);
      }

      // get arrhythmia settings for the form
      // Convert pause duration to seconds and hpFilter to Hz
      const formattedArrhythmiaSettings = keyBy(
        arrhythmiaSettingsResponse.map((setting) => {
          const pauseDuration = setting.pauseDuration / 1000;
          const hpFilter = setting.hpFilter / 100;

          return {...setting, pauseDuration, hpFilter};
        }),
        "id"
      );
      // get device configurations for the form
      const formattedDeviceConfigurations = keyBy(deviceConfigurationsResponse, "id");

      try {
        const foundSettings = JSON.parse(settingsResponse[0].originalData);
        if (study.pendingDuration) {
          foundSettings.studyDays = Math.ceil(study.pendingDuration / 24);
        } else if (!foundSettings.studyDays && foundSettings.studyHours) {
          foundSettings.studyDays = Math.ceil(foundSettings.studyHours / 24);
        }

        // Convert from milliseconds to seconds
        foundSettings.pauseDuration /= 1000;
        // Convert from hz/100 to hz
        foundSettings.hpFilter /= 100;

        setOldSettings((prev) => ({...prev, ...foundSettings}));
      } catch (err) {
        /* do nothing */
      }

      const foundUser = settingsResponse.find((setting) => setting.status === "pending")?.createdBy || "";

      setArrhythmiaSettings(formattedArrhythmiaSettings);
      setDeviceConfigurations(formattedDeviceConfigurations);
      setPendingUpdateSettingsUser(foundUser);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [study, enrollmentToUse]);

  useInterval(getResumeStudyData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Helper Function
  //---------------------------------------------------------------------------
  const enrollmentStartTime = React.useMemo(() => {
    return DateTime.fromISO(enrollmentStartTimeToUse);
  }, [enrollmentStartTimeToUse]);

  const elapsedStudyDays = React.useMemo(() => {
    return Math.floor(DateTime.now().diff(enrollmentStartTime, ["days"]).days);
  }, [enrollmentStartTime]);

  const minTotalStudyDays = React.useMemo(() => {
    return elapsedStudyDays + 1;
  }, [elapsedStudyDays]);

  const associatedStudyDays = React.useMemo(() => {
    if (study.initialStudyAssociation) {
      return Math.ceil(study.initialStudyAssociation.initialStudy.pendingDuration / 24);
    }
    if (study.followUpStudyAssociation) {
      return Math.ceil(study.followUpStudyAssociation.followUp.pendingDuration / 24);
    }
    return null;
  }, [study.initialStudyAssociation, study.followUpStudyAssociation]);

  const resumeStudyDisclaimer = React.useMemo(() => {
    const endDate = DateTime.fromISO(study.studyEndDate);
    const startDate = DateTime.fromISO(study.studyStartDate);

    const daysOfRecording = Math.round(endDate.diff(startDate, ["days"]).days);
    const daysSinceEnd = Math.floor(DateTime.now().diff(endDate, ["days"]).days);

    const studyEndDate = endDate.toFormat("yyyy-LL-dd");
    const maxDays = 30;

    let disclaimer = `Note: A recording of ${daysOfRecording} day${
      daysOfRecording === 1 ? "" : "s"
    } was completed `;
    if (daysSinceEnd > 0) {
      disclaimer += `${daysSinceEnd} day${daysSinceEnd === 1 ? "" : "s"} ago `;
    } else if (daysSinceEnd === 0) {
      disclaimer += "within the last day ";
    }
    disclaimer += `on ${studyEndDate}. It has been ${elapsedStudyDays} day${
      elapsedStudyDays === 1 ? "" : "s"
    } since the study started, so the valid study duration `;
    if (minTotalStudyDays === maxDays) {
      disclaimer += `is ${minTotalStudyDays} days.`;
    } else {
      disclaimer += `range is ${minTotalStudyDays} to ${maxDays} days.`;
    }
    return disclaimer;
  }, [minTotalStudyDays, elapsedStudyDays, study]);

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const {handleSubmit, control, setValue, watch} = useForm();
  const {isDirty} = useFormState({control});

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      //---------------------------------------------------------------------------
      // Construct settings data for the device
      //---------------------------------------------------------------------------
      let studyHours = Number(data.studyDays) * 24;
      // If this study is part of the follow-up study workflow, send the total study duration to the device
      if (features.followUpStudy && !!study.followUpStudyAssociation) {
        studyHours += study.followUpStudyAssociation.followUp?.pendingDuration || 0;
      } else if (features.followUpStudy && !!study.initialStudyAssociation) {
        studyHours += study.initialStudyAssociation.initialStudy?.pendingDuration || 0;
      }

      const deviceConfiguration = deviceConfigurations[data.deviceConfig];
      if (deviceConfiguration.deviceType) {
        deviceConfiguration.deviceType = "h3r";
      }

      const updateSettingsData = {
        studyType: oldSettings.studyType,
        tachyBpm: Number(data.tachyBpm),
        bradyBpm: Number(data.bradyBpm),
        pauseDuration: Number(data.pauseDuration) * 1000, // convert seconds to milliseconds
        episodeDuration: Number(data.episodeDuration),
        hpFilter: Number(data.hpFilter) * 100, // convert hz to hz/100
        lpFilter: Number(data.lpFilter),
        notchFilter: Number(data.notchFilter),
        pinCode: oldSettings.pinCode,
        deviceConfig: deviceConfiguration,
        studyNote: data.comment || oldSettings.studyNote || "",
        studyHours,
      };
      const updateSettingsAction = {
        deviceId: enrollmentToUse.deviceId,
        enrollmentId: enrollmentToUse.enrollmentId,
        facilityId: study.facilityId,
        createdBy: fullName,
        comment: data.comment,
        data: JSON.stringify(updateSettingsData),
      };

      //---------------------------------------------------------------------------
      // Create the update settings action!
      //---------------------------------------------------------------------------
      try {
        await axios({
          url: "/actions/resumeStudy",
          method: "post",
          data: updateSettingsAction,
        });

        handleClose();
      } catch (err) {
        setError(err.message);
      }
      setSubmitting(false);
    },
    [
      features.followUpStudy,
      study.followUpStudyAssociation,
      study.initialStudyAssociation,
      study.facilityId,
      deviceConfigurations,
      oldSettings.studyType,
      oldSettings.pinCode,
      oldSettings.studyNote,
      enrollmentToUse.deviceId,
      enrollmentToUse.enrollmentId,
      fullName,
      handleClose,
    ]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {loading && <TableLoading />}
      {!loading && (
        <TabPanel value="resumeStudy" data-cy="resume-study">
          <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
          {pendingUpdateSettingsUser !== "" && (
            <Alert
              message={`The settings changes requested by ${pendingUpdateSettingsUser} have not been sent to the device. The settings displayed below include the pending changes.`}
              level="warning"
              otherProps={{mb: 2}}
            />
          )}
          {!canResumeStudy && (
            <Alert
              message="A resume study action is already being sent to the device."
              level="warning"
              otherProps={{mb: 2}}
            />
          )}
          {study.studyType !== "cardiacRehab" && (
            <Alert message={resumeStudyDisclaimer} level="info" otherProps={{mb: 2}} />
          )}

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} container rowGap={1}>
              <Grid item xs={12} data-cy="info-device-serial">
                <IconWithText
                  icon={<Smartphone color="tertiary" />}
                  text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
                />
              </Grid>
              <Grid item xs={12} data-cy="info-study-type">
                <IconWithText
                  icon={<Assignment color="tertiary" />}
                  text={<Typography variant="body2">{displayableStudyTypes[study.studyType]}</Typography>}
                />
              </Grid>
            </Grid>

            <FormProvider {...{control, watch, setValue}}>
              {study.studyType !== "cardiacRehab" && (
                <Grid item xs={6} sx={{display: "inline-flex"}}>
                  <StudyDurationField
                    studyDays={oldSettings.studyDays}
                    minTotalStudyDays={minTotalStudyDays}
                    elapsedStudyDays={elapsedStudyDays}
                    enrollmentStartTime={enrollmentStartTime}
                    timeZone={study.timeZone}
                    disabled={!canResumeStudy}
                    associatedStudyDays={associatedStudyDays}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <SharedSettingsFields
                  settings={oldSettings}
                  arrhythmiaSettings={arrhythmiaSettings}
                  deviceConfigurations={deviceConfigurations}
                  disabled={!canResumeStudy}
                />
              </Grid>
              <Grid item xs={12}>
                <CommentField disabled={!canResumeStudy} />
              </Grid>
            </FormProvider>

            <Grid item xs={12} display="inline-flex" justifyContent="flex-end">
              <Box mx={3}>
                <CancelButton
                  color="secondary"
                  isDirty={isDirty}
                  onClick={handleClose}
                  data-cy="cancel-action-button"
                >
                  Cancel
                </CancelButton>
              </Box>
              <LoadingButton
                data-cy="submit-action-button"
                disabled={submitting || !canResumeStudy || !isDirty}
                variant="contained"
                color="secondary"
                loading={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                Resume Study
              </LoadingButton>
            </Grid>
          </Grid>
        </TabPanel>
      )}
    </>
  );
}

ResumeStudyForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  enrollmentStartTimeToUse: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ResumeStudyForm;
