/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";

const millisecondRegex = /^\d+(\.\d{1,3})?$/;
const hundredthRegex = /^\d+(\.\d{1,2})?$/;

function SharedSettingsFields({
  // Props
  settings,
  deviceConfigurations,
  arrhythmiaSettings,
  disabled = false,
}) {
  //---------------------------------------------------------------------------
  // State and callbacks for the menu
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = React.useCallback(() => setAnchorEl(null), []);

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control, setValue, watch} = useFormContext();

  const watchBradyBPM = watch("bradyBpm") || settings.bradyBpm || "";
  const watchTachyBPM = watch("tachyBpm") || settings.tachyBpm || "";

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const handlePrefillingValues = React.useCallback(
    (event) => {
      const settingId = event.target.id;
      const newArrhythmiaSetting = arrhythmiaSettings[settingId];

      setValue("tachyBpm", newArrhythmiaSetting.tachyBpm);
      setValue("bradyBpm", newArrhythmiaSetting.bradyBpm);
      setValue("pauseDuration", newArrhythmiaSetting.pauseDuration);
      setValue("episodeDuration", newArrhythmiaSetting.episodeDuration);
      setValue("hpFilter", newArrhythmiaSetting.hpFilter);
      setValue("lpFilter", newArrhythmiaSetting.lpFilter);
      setValue("notchFilter", newArrhythmiaSetting.notchFilter);
    },
    [arrhythmiaSettings, setValue]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} align="center">
        <Button
          size="large"
          variant="outlined"
          data-cy="preset-setting-button"
          color="secondary"
          onClick={handleOpen}
          sx={{width: "250px", maxWidth: true}}
        >
          Select Preset
          <ArrowDropDown sx={{paddingLeft: 1}} />
        </Button>
        <Menu
          data-cy="arrhythmia-select-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClick={handleClose}
          MenuListProps={{sx: {width: anchorEl && anchorEl.offsetWidth}}}
        >
          <MenuList>
            {Object.values(arrhythmiaSettings).map((setting) => (
              <MenuItem
                id={setting.id}
                key={setting.name}
                data-cy={`setting-option-${setting.id}`}
                onClick={handlePrefillingValues}
              >
                {setting.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Grid>

      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.tachyBpm || ""}
          label="Tachycardia Threshold (60-300 BPM)"
          disabled={disabled}
          type="number"
          name="tachyBpm"
          data-cy="tachyBpm-input"
          rules={{
            required: "Tachycardia threshold is required",
            min: {value: 60, message: "Tachycardia threshold must be greater than or equal to 60"},
            max: {value: 300, message: "Tachycardia threshold must be less than or equal to 300"},
            validate: {
              integer: (value) =>
                Number.isInteger(Number(value)) || "Tachycardia threshold must be a whole number",
              greaterThanBrady: (value) =>
                Number(value) >= Number(watchBradyBPM) ||
                "Tachycardia threshold must be greater than or equal to Bradycardia threshold",
            },
          }}
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 60, max: 300}}}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.bradyBpm || ""}
          label="Bradycardia Threshold (30-100 BPM)"
          disabled={disabled}
          type="number"
          name="bradyBpm"
          data-cy="bradyBpm-input"
          rules={{
            required: "Bradycardia threshold is required",
            min: {value: 30, message: "Bradycardia threshold must be greater than or equal to 30"},
            max: {value: 100, message: "Bradycardia threshold must be less than or equal to 100"},
            validate: {
              integer: (value) =>
                Number.isInteger(Number(value)) || "Bradycardia threshold must be a whole number",
              lessThanTachy: (value) =>
                Number(value) <= Number(watchTachyBPM) ||
                "Bradycardia threshold must be less than or equal to Tachycardia threshold",
            },
          }}
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 30, max: 100}}}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.pauseDuration || ""}
          label="Pause Threshold (1.5-15 seconds)"
          disabled={disabled}
          name="pauseDuration"
          type="number"
          data-cy="pauseDuration-input"
          rules={{
            required: "Pause threshold is required",
            min: {value: 1.5, message: "Pause threshold must be greater than or equal to 1.5"},
            max: {value: 15, message: "Pause threshold must be less than or equal to 15"},
            validate: {
              precision: (value) =>
                millisecondRegex.test(value) || "Pause threshold must be to the nearest millisecond",
            },
          }}
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 1.5, max: 15, step: 0.001}}}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.episodeDuration || ""}
          label="Episode Duration Threshold (0-300 seconds)"
          disabled={disabled}
          name="episodeDuration"
          data-cy="episodeDuration-input"
          type="number"
          rules={{
            required: "Episode duration threshold is required",
            min: {value: 0, message: "Episode duration threshold must be greater than or equal to 0"},
            max: {value: 300, message: "Episode duration threshold must be less than or equal to 300"},
            validate: {
              integer: (value) =>
                Number.isInteger(Number(value)) || "Episode duration threshold must be a whole number",
            },
          }}
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 0, max: 300}}}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.hpFilter || ""}
          label="High Pass Filter (0-1 Hz)"
          name="hpFilter"
          data-cy="hpFilter-input"
          type="number"
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 0, max: 1, step: 0.01}}}
          rules={{
            required: "High pass filter is required",
            min: {value: 0, message: "High pass filter must be greater than or equal to 0"},
            max: {value: 1, message: "High pass filter must be less than or equal to 1"},
            validate: {
              precision: (value) =>
                hundredthRegex.test(value) || "High pass filter must be to the nearest hundredth of a hertz",
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.lpFilter || ""}
          label="Low Pass Filter (0-100 Hz)"
          name="lpFilter"
          data-cy="lpFilter-input"
          type="number"
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 0, max: 100}}}
          rules={{
            required: "Low pass filter is required",
            min: {value: 0, message: "Low pass filter must be greater than or equal to 0"},
            max: {value: 100, message: "Low pass filter must be less than or equal to 100"},
            validate: {
              integer: (value) => Number.isInteger(Number(value)) || "Low pass filter must be a whole number",
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.notchFilter || ""}
          label="Notch Filter (0-100 Hz)"
          name="notchFilter"
          data-cy="notchFilter-input"
          type="number"
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: 0, max: 900}}}
          rules={{
            required: "Notch filter is required",
            min: {value: 0, message: "Notch filter must be greater than or equal to 0"},
            max: {value: 900, message: "Notch filter must be less than or equal to 100"},
            validate: {
              integer: (value) => Number.isInteger(Number(value)) || "Notch filter must be a whole number",
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormStringInput
          control={control}
          defaultValue={settings.deviceConfig?.id || ""}
          disabled={disabled}
          label="Device Configuration"
          name="deviceConfig"
          data-cy="device-configuration-select"
          options={Object.values(deviceConfigurations)}
          rules={{required: "Device configuration is required"}}
          size="small"
          otherProps={{variant: "outlined"}}
        />
      </Grid>
    </Grid>
  );
}

SharedSettingsFields.propTypes = {
  settings: PropTypes.object,
  arrhythmiaSettings: PropTypes.object.isRequired,
  deviceConfigurations: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default SharedSettingsFields;
