/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

function ForceActionsFailureForm({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  const {fullName} = useJwt();

  const [error, setError] = React.useState(null);

  const [submitting, setSubmitting] = React.useState(false);

  const {control, handleSubmit} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      const actionData = {
        deviceId: enrollmentToUse.deviceId,
        enrollmentId: enrollmentToUse.enrollmentId,
        facilityId: study.facilityId,
        createdBy: fullName,
        comment: data.comment,
        data: "",
      };

      try {
        await axios({
          url: "/actions/forceActionsFailure",
          method: "post",
          data: actionData,
        });

        handleClose();
      } catch (err) {
        setError(err.message);
      }
      setSubmitting(false);
    },
    [fullName, enrollmentToUse, study, handleClose]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <TabPanel value="forceActionsFailure" data-cy="force-actions-failure">
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <IconWithText
            icon={<Smartphone color="tertiary" />}
            text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
          />
        </Grid>

        <FormProvider {...{control}}>
          <Grid item xs={12}>
            <CommentField control={control} />
          </Grid>
        </FormProvider>

        <Grid item xs={12} display="inline-flex" justifyContent="flex-end">
          <Box mx={3}>
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleClose}
              data-cy="cancel-action-button"
            >
              Cancel
            </CancelButton>
          </Box>
          <LoadingButton
            data-cy="submit-action-button"
            disabled={submitting || !isDirty}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Force Actions Failure
          </LoadingButton>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

ForceActionsFailureForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ForceActionsFailureForm;
