/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import {useStudiesDispatch} from "../../../../contexts/StudiesContext.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

function MarkStudyAsFailed({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [inboxCount, setInboxCount] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);
  React.useEffect(() => {
    const getInboxItemsCount = async () => {
      try {
        const {
          headers: {count},
        } = await axios({
          method: "get",
          url: "/inboxItems",
          params: {
            "$enrollment.study.id$": study.id,
            completed: false,
            limit: 0,
          },
        });
        setInboxCount(count);
      } catch (err) {
        setError(err.message);
      }
    };

    getInboxItemsCount();
  }, [study.id, setInboxCount]);

  //---------------------------------------------------------------------------
  // Calculate label
  //---------------------------------------------------------------------------
  const inboxAndReportCountLabel = React.useMemo(() => {
    let displayedInboxItemLabel;
    let displayedReportLabel;

    if (inboxCount === 1) {
      displayedInboxItemLabel = `is ${inboxCount} unanalyzed item`;
    } else {
      displayedInboxItemLabel = `are ${inboxCount} unanalyzed items`;
    }

    if (study.reportCount === 1) {
      displayedReportLabel = `${study.reportCount} report`;
    } else {
      displayedReportLabel = `${study.reportCount} reports`;
    }

    return `There ${displayedInboxItemLabel} and ${displayedReportLabel} for this study.`;
  }, [inboxCount, study.reportCount]);

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const {control, handleSubmit} = useForm();
  const {isDirty} = useFormState({control});
  const dispatch = useStudiesDispatch();
  const {fullName} = useJwt();

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      const newStudyNote = {
        fullName,
        timestamp: DateTime.now().valueOf(),
        note: data.comment,
      };

      try {
        await Promise.all([
          axios({
            url: `/studies/${study.id}/fail`,
            method: "post",
          }),
          axios({
            method: "post",
            url: `/studies/studyNotes/${study.id}`,
            data: newStudyNote,
          }),
        ]);

        const {
          data: [updatedStudy],
        } = await axios({
          method: "get",
          url: "/studies",
          params: {id: study.id},
        });
        dispatch({type: "updated", updatedElement: updatedStudy});

        handleClose();
      } catch (err) {
        setError(err.message);
      }
      setSubmitting(false);
    },
    [fullName, study.id, dispatch, handleClose]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <TabPanel value="markStudyAsFailed" data-cy="mark-study-as-failed">
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Alert
        message="Are you sure you want to mark this study as failed? This cannot be undone."
        level="error"
        otherProps={{mb: 2}}
      />
      {(inboxCount > 0 || study.reportCount > 0) && (
        <Alert
          message={
            <>
              {inboxAndReportCountLabel}
              <br />
              All items and reports will be hidden for this study.
            </>
          }
          level="warning"
          otherProps={{mb: 2}}
        />
      )}

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <IconWithText
            icon={<Smartphone color="tertiary" />}
            text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography>Please enter the device serial to confirm this action.</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormStringInput
            control={control}
            defaultValue=""
            label="Device serial"
            name="confirmDevice"
            data-cy="confirm-device-input"
            rules={{
              required: "Device serial is required",
              validate: {
                matchingSerial: (selectedDevice) => {
                  if (enrollmentToUse.tzSerial && selectedDevice !== enrollmentToUse.tzSerial) {
                    return "The device serial must match the serial of the device assigned to this study";
                  }
                  return true;
                },
              },
            }}
            size="small"
            otherProps={{variant: "outlined"}}
          />
        </Grid>

        <FormProvider {...{control}}>
          <Grid item xs={12}>
            <CommentField />
          </Grid>
        </FormProvider>

        <Grid item xs={12} display="inline-flex" justifyContent="flex-end">
          <Box mx={3}>
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleClose}
              data-cy="cancel-action-button"
            >
              Cancel
            </CancelButton>
          </Box>
          <LoadingButton
            data-cy="submit-action-button"
            disabled={submitting || !isDirty}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Mark Study as Failed
          </LoadingButton>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

MarkStudyAsFailed.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MarkStudyAsFailed;
