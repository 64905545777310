/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DateAndTime from "../../../../components/DateAndTime/DateAndTime.jsx";
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";

function StudyDurationField({
  // Props
  studyDays,
  minTotalStudyDays,
  elapsedStudyDays,
  enrollmentStartTime,
  timeZone,
  disabled = false,
  associatedStudyDays,
}) {
  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control, watch} = useFormContext();

  //---------------------------------------------------------------------------
  // Watchers
  //---------------------------------------------------------------------------
  const watchStudyDays =
    watch("studyDays") || (studyDays < minTotalStudyDays ? minTotalStudyDays : studyDays);

  const estimatedEnd = React.useMemo(() => {
    const end = enrollmentStartTime.plus({days: watchStudyDays});
    return <DateAndTime datetime={end} zone={timeZone} />;
  }, [enrollmentStartTime, timeZone, watchStudyDays]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Grid item xs={3} align="center">
        <p>Day {elapsedStudyDays} of </p>
      </Grid>
      <Grid item xs={9}>
        <FormStringInput
          control={control}
          defaultValue={studyDays < minTotalStudyDays ? minTotalStudyDays : studyDays}
          label={`Total Study Duration ${minTotalStudyDays <= 30 ? `(${minTotalStudyDays}-30 Days)` : ""}`}
          name="studyDays"
          disabled={disabled}
          type="number"
          rules={{
            required: "Total study duration is required",
            min: {
              value: minTotalStudyDays,
              message: `Total study duration must be greater than or equal to ${minTotalStudyDays}`,
            },
            max: {value: 30, message: "Total study duration must be less than or equal to 30"},
            validate: {
              integer: (value) => Number.isInteger(Number(value)) || "Study duration must be an integer",
              followUp: (value) => {
                if (!associatedStudyDays || !Number.isInteger(associatedStudyDays)) {
                  return true;
                }
                return (
                  Number(value) + Number(associatedStudyDays) <= 30 ||
                  "The total study duration of both studies must be less than or equal to 30"
                );
              },
            },
          }}
          size="small"
          otherProps={{variant: "outlined", inputProps: {min: minTotalStudyDays, max: 30}}}
          data-cy="study-duration-input"
        />
        <Typography variant="caption">Estimated End Date: {estimatedEnd}</Typography>
      </Grid>
    </>
  );
}

StudyDurationField.propTypes = {
  studyDays: PropTypes.number,
  minTotalStudyDays: PropTypes.number.isRequired,
  elapsedStudyDays: PropTypes.number.isRequired,
  enrollmentStartTime: PropTypes.object.isRequired,
  timeZone: PropTypes.string,
  disabled: PropTypes.bool,
  associatedStudyDays: PropTypes.number,
};

export default StudyDurationField;
